<template>
  <div :class="`wrapper  wrapper--${styling_bu} m-3 p-2`">
    <div class="text-center p-2">
      <h2 class="text-center">Afleveringen per dag</h2>
      <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">{{ day }}-{{ month }}-{{ year }}</span>
      <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div v-else class="flex">
      <span v-if="deliveries_per_day.length == 0" class="w100 text-center"
        >Geen Data</span
      >
      <table v-else :class="`w-100 table__border--${styling_bu}`">
        <thead :class="`table__head--${styling_bu}`">
          <tr>
            <th class="table__head--text">Foto</th>
            <th class="table__head--text">Item</th>
            <th class="table__head--text">Klant</th>
            <th class="table__head--text">Verkoper</th>
            <th class="table__head--text">Dagen</th>
            <th class="table__head--text">Inkopen</th>
            <th class="table__head--text">Kosten</th>
            <th class="table__head--text">Commissie</th>
            <th class="table__head--text">Verkopen</th>
            <th class="table__head--text">Winst</th>
            <th class="table__head--text">Afschrijving</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in deliveries_per_day" :key="key">
            <td class="table__cell--default">
              <img
                :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/original`"
                class="table__picture--max-width"
              />
            </td>
            <td class="table__cell--default">
              <div>
                <ImageHover :itemnummer="item.nummer" :bu="bu" /><br />
                {{ item.soortnaam }}<br />
                {{ item.opbouwnaam }}<br />
                {{ item.merktype }}<br />
                {{ item.regjaar }}
              </div>
            </td>
            <td class="table__cell--default">
              <div>
                <router-link
                  :to="{ name: 'klantpagina', params: { id: item.klant } }"
                  >{{ item.klant }}</router-link
                ><br />
                {{ item.klantnaam }}<br />
                {{ item.adres }}<br />
                {{ item.stad }}<br />
                {{ item.landnaam }}
              </div>
            </td>
            <td class="table__cell--default">{{ item.naam }}</td>
            <td class="table__cell--default table__data--right">
              {{ item.stadagen }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.inkoopbedrag }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.kostenbedrag }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.commisiebedrag }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.verkoopbedrag }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.winst }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.afschrijving }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr  class="table__foot">
            <th class="table__foot--padding">Totaal:</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.inkopen }}</th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.kosten }}</th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.commisie }}</th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.verkopen }}</th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.winst }}</th>
            <th class="table__data--right table__foot--padding">€ {{ deliveries_per_day_total.afschrijving }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import ImageHover from "@/components/ImageHover.vue";
import { subtractTime, addTime } from "@/functions/date.js";

export default {
  props: ["bu"],
  components: { Loading, ImageHover },
  data: () => ({
    loading: true,
    deliveries_per_day: null,
    deliveries_per_day_total: null,
    styling_bu: null,
    full_date: null,
    day: null,
    month: null,
    year: null,
  }),
  created() {
    this.full_date = this.moment().subtract(1, "days");
    this.getData(this.bu);
    this.bu == 20 ? this.styling_bu = 200 : this.styling_bu = this.bu
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
      this.loading = true;
      this.deliveries_per_day = null;
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.day = this.full_date.date();
      this.month = this.full_date.month() + 1;
      this.year = this.full_date.year();
      request(
        `deliveries-per-day/${bu}/${this.year}/${this.month}/${this.day}`,
        "GET"
      ).then(({ deliveries_per_day, deliveries_per_day_total }) => {
        this.deliveries_per_day = deliveries_per_day;
        this.deliveries_per_day_total = deliveries_per_day_total;
        this.loading = false;
      });
    },

    previousDay() {
      this.full_date = subtractTime(1, this.full_date, "days");
      this.getData(this.bu);
    },

    nextDay() {
      this.full_date = addTime(1, this.full_date, "days");
      this.getData(this.bu);
    },
  },
};
</script>
